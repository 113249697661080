import * as React from "react"

import Layout from "../components/layout"
import Properties from "../components/properties"
import Seo from "../components/seo"

const seo = {
  metaTitle: 'Aanbod',
}

const PropertiesPage = () => (
  <Layout active="aanbod" page="aanbod">
    <Seo seo={seo} />
    <Properties title="Te koop" types={['sale', 'sold']} />
    <Properties title="Te huur" types={['rent', 'rented']} />
  </Layout>
)

export default PropertiesPage
