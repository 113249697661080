import * as React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby";
import Image from "./image";

const Properties = ({ title, types, items, without }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProperty {
        edges {
          node {
            created_at
            title
            description
            location
            type
            features {
              title
              waarde
              icoon
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 300)
                }
              }
            }
            slug
          }
        }
      }

      strapiPagina {
        offer_empty
      }
    }
  `);

  const sorting = ['sale', 'rent', 'rented', 'sold'];

  const properties = data.allStrapiProperty.edges
    .map(item => item.node)
    .filter(item => without.indexOf(item.slug) === -1)
    .filter(item => types.indexOf(item.type) > -1)
    .sort((a, b) => 
      sorting.indexOf(a.type) - sorting.indexOf(b.type) ||
        +new Date(b.created_at) - +new Date(a.created_at))
    .slice(0, items);

  return (
    <section className="featured-properties">
      <div className="container">
        <header className="entry-header heading">
          <h2 className="entry-title">{title}</h2>
        </header>
        <div className="row">
          {properties.length === 0 && (
            <p style={{ textAlign: 'center', fontSize: 18, marginTop: -20 }} dangerouslySetInnerHTML={{__html: data.strapiPagina.offer_empty }}></p>
          )}
          {properties.map(item => (
          <Link to={'/aanbod/' + item.slug} className="custom-col-4" style={{ display: 'block', opacity: ['sold', 'rented'].indexOf(item.type) > -1 ? .85 : 1 }}>
            <div className="post">
              <div className="post-featured-image">
                <Image
                  image={item.image.localFile.childImageSharp.gatsbyImageData}
                  alt={`Afbeelding van ${item.title}`}
                  sizes={'(min-width: 1200px) 360px, (min-width: 768px) 33vw, 50vw'}
                />
                {item.slug !== 'uw-pand-hier' && item.type && (<span className="price">
                  {item.type === 'sale' ? 'Te koop' : ''}
                  {item.type === 'rent' ? 'Te huur' : ''}
                  {item.type === 'sold' ? 'Verkocht' : ''}
                  {item.type === 'rented' ? 'Verhuurd' : ''}
                </span>)}
              </div>

              <header className="entry-header">
                {item.location && (<span className="location">{item.location}</span>)}
                <h3 className="entry-title">{item.title}</h3>
              </header>

              <div className="entry-content">
                <p>{item.description}</p>
              </div>

              {/* {item.features.length > 0 && (
                <footer className="entry-footer">
                  <div className="property-meta entry-meta" style={{ display: 'flex' }}>
                    {item.features.slice(0, 1).map(feature => (
                      <div className="meta-wrapper" style={{ float: 'none', width: '100%' }}>
                        <span className="meta-icon"><i className={'fa fa-' + feature.icoon}></i> </span>
                        <span className="meta-unit">{feature.title} </span>
                        <span className="meta-value">{feature.waarde} </span>
                      </div>
                    ))}
                  </div>
                </footer>
              )} */}
            </div>
          </Link>
        ))}
      </div>
      {items < 10 && properties.length > 0 && (<div className="row">
        <div className="custom-col-4" style={{ textAlign: "center" }}>
          <Link to="/aanbod" className="box-button">Volledig aanbod</Link>
        </div>
      </div>)}
    </div>
  </section>
  );
}

Properties.propTypes = {
  title: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  without: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.number.isRequired
}

Properties.defaultProps = {
  title: ``,
  types: ['sale', 'rent', 'rented', 'sold'],
  without: [],
  items: 999
}

export default Properties
